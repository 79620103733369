.gallery-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  .gallery-link {
    display: block;
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 1em;
}