
.service-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }


  
  .aboutme-image {
    width: 35%;
    height: 35%;
    object-fit: cover;
}